@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #F4F4F4 !important;
    /* Задаем цвет фона для всей страницы */
    font-family: 'Montserrat', sans-serif;
    /* Задаем шрифт Montserrat */
}

.report-container {
    margin: 20px;
}

.user-info,
.contact-info {
    display: flex;
    /* Размещаем элементы внутри контейнера в строку */
    align-items: center;
    /* Выравниваем элементы по вертикали по центру */
    margin: 20px;
    /* Добавляем отступ для разделения соседних блоков */
}

.user-info h2,
.contact-info h2,
.user-info p,
.contact-info p {
    margin: 0;
    /* Убираем отступы для текста h2 и p внутри контейнеров */
}

.user-info h2 {
    font-size: 100px;
    /* Уменьшаем размер шрифта для заголовка h2 */
    font-weight: bold;
    color: #232322;
    margin-top: 100px;
    /* Добавляем отступ сверху */
}

.user-info p,
.contact-info p {
    font-size: 24px;
    /* Уменьшаем размер шрифта для текста p */
    color: #232322;
    text-align: left;
}

.user-info img {
    margin-top: 100px;
    margin-right: 10px;
    /* Отступ справа от изображения для разделения с текстом */
    margin-left: 150px;
}

.contact-info img {
    margin-right: 10px;
    /* Отступ справа от изображения для разделения с текстом */
    margin-left: 250px;
}

.params-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.params-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Разделить на две колонки с одинаковой шириной */
    gap: 15px;
    /* Добавить пространство между элементами */
}

.param-box {
    border: 5px solid #fff;
    border-radius: 40px;
    padding: 30px;
    background-color: #F4F4F4;
    position: relative;
}

.param-box img.param-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 40px auto;
    /* Добавляем верхний и нижний отступ по 10px */
    padding: 10px;
}

.param-box-extra-margin {
    margin: 15px;
}


.param-name {
    cursor: pointer;
    position: relative;
    color: #232322;
}

.param-name:hover,
.param-name.expanded {
    color: #E85612;
}

.param-name::after {
    content: "+";
    position: absolute;
    top: 0px;
    /* Располагаем плюс выше элемента */
    right: 15px;
    /* Отступаем от правого края */
    color: #232322;
}

.param-name::before {
    content: "";
    /* Добавляем псевдоэлемент для создания черной полосы */
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
}

.param-name.expanded::after {
    content: "-";
    color: #232322;
}

.param-name.expanded::after,
.param-name:hover::after {
    color: #E85612;
}

.param-description {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    position: relative;
    /* Добавлено для позиционирования псевдоэлемента */
}

.param-description::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: black;
    margin-top: 10px;
    /* Регулируйте отступ под черной полосой по вашему желанию */
}

.param-icon img {
    height: 27px;
}

.param-description-expanded {
    max-height: 1000px;
    opacity: 1;
}

.param-description p {
    font-size: 12px;
    /* Измените размер шрифта по вашему выбору */
}

.param-box p {
    font-size: 14px;
    /* Прижимаем текст к левому краю */
}

.param-description p,
.param-box p {
    text-align: left;
    /* Прижимаем текст к левому краю */
}

.param-box:nth-child(7),
.params-row .param-box:nth-child(1) {
    background-color: #f7c3ab;
}

.param-box:nth-child(8),
.params-row .param-box:nth-child(2) {
    background-color: #f7c3ab;
}

.param-box:nth-child(2),
.param-box:nth-child(6) {
    background-color: #f7c3ab;
    /* Задаем цвет фона для выбранных параметров */
}

.param-image-container {
    position: relative;
    /* Сделаем этот контейнер относительным */
}

.image-text-left {
    position: absolute;
    color: #E85612;
    font-weight: bold;
    z-index: 1;
    top: 0px;
    /* Положение текста сверху */
    left: 20px;
    /* Положение текста слева */
    font-size: 12px;
}

.image-text-right {
    position: absolute;
    color: #E85612;
    font-weight: bold;
    z-index: 1;
    top: 0px;
    /* Положение текста сверху */
    right: 20px;
    /* Положение текста справа */
    font-size: 12px;
}

.bottom-box {
    background-color: #686868;
    padding: 20px;
}

.bottom-box .param-box {
    border: 5px solid #fff;
    border-radius: 40px;
    padding: 20px;
    margin-bottom: 15px;
    background-color: #F4F4F4;
}

.bottom-box .param-name {
    color: #232322;
}

.bottom-box .param-description p {
    text-align: left;
}

.bottom-box .param-image-container {
    margin-top: 20px;
    text-align: center;
}

.special-box {
    background-color: #F4F4F4 !important;
    /* Изменяем цвет только для этого элемента */
    grid-column: 1 / -1;
}

.special-box2 {
    background-color: #f7c3ab !important;
    /* Изменяем цвет только для этого элемента */
    /* grid-column: 1 / -1; Расположение на весь экран  */
}


/* Отступ справа от изображения для разделения с текстом */
/* .mid-text {
    margin-right: 850px;
} */



.additional-text {
    display: flex;
    flex-direction: column;
    /* Расположение элементов в колонку */
    gap: 20px;
    /* Расстояние между элементами */
}

.competency-item {
    display: flex;
    align-items: center;
    /* Выравнивание по центру по вертикали */
}

.competency-item img {
    margin-right: 500px;
    /* Добавляем отступ справа */
}

.text {
    flex: 1;
    /* Расширяет элемент до остаточного пространства */
}

.image {
    margin-left: 20px;
    /* Отступ между текстом и изображением */
}

.additional-text p {
    font-size: 14px;
    color: #232322;
    margin: 20px 0;
    /* Добавляем отступы сверху и снизу для текстовых блоков */
    text-align: left;
}

.back-button {
    position: absolute;
    cursor: pointer;
    color: #E85612;
    font-size: 18px;
    text-decoration: none;
}

.back-button:hover {
    text-decoration: underline;
}



/* Стили для сворачивания блока карты компетенций
.additional-text { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 50px; 
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.additional-text.expanded {
    max-height: 1000px;
    opacity: 1;
}

.additional-text p {
    font-size: 14px;
    font-weight: bold;
    color: #232322;
    margin: 20px 0; 
    text-align: left;
}

.toggle-button {
    position: absolute;
    top: 20px; 
    right: 50px; 
    width: 20px;
    background: none;
    border: none;
    color: #232322;
    font-size: 50px; 
    cursor: pointer;
    padding: 2px; 
    z-index: 2;
}

.toggle-button:hover {
    background: none; 
    color: #E85612; 
}
*/


/* Добавляем медиа-запрос для мобильных устройств с максимальной шириной 600px */
@media screen and (max-width: 1164px) {
    body,
    .report-container {
        overflow-x: hidden;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* Это важно для правильного учета внутренних отступов и размеров элементов */
    }

    .user-info {
        justify-content: flex-start;
        /* Выравниваем содержимое к левому краю */
    }

    .user-info h2 {
        font-size: 60px;
        /* Уменьшаем размер шрифта */
        width: 100%;
        /* Ширина элемента равна 100% ширины родительского контейнера */
        word-wrap: break-word;
        /* Позволяет переносить слова на следующую строку */
        overflow-wrap: break-word;
        /* Альтернативное свойство для поддержки старых браузеров */
    }

    .contact-info p {
        font-size: 12px;
        /* Уменьшаем размер шрифта */
        width: 100%;
        /* Ширина элемента равна 100% ширины родительского контейнера */
        word-wrap: break-word;
        /* Позволяет переносить слова на следующую строку */
        overflow-wrap: break-word;
        /* Альтернативное свойство для поддержки старых браузеров */
    }
    

    .user-info img{
        width: 60px;
        order: -1;
    /* Перемещаем изображение перед заголовком */
    margin-bottom: 10px;
    /* Добавляем небольшой отступ между изображением и заголовком */
    margin-left: 0px;
    }
    
    .contact-info img {
        width: 50px;
        /* Устанавливаем ширину изображения */
        /* Или вы можете использовать max-width */
        /* max-width: 50px; */
        /* Если вы хотите сохранить пропорции, установите ширину, а высота будет автоматически рассчитана */
        /* Автоматический подсчет высоты */
        margin-left: 0;
        /* Убираем левый отступ для изображения */
        margin-top: 0;
        /* Убираем верхний отступ для изображения */
    }

    .param-box {
        margin-top: 10px;
        margin-bottom: 10px;
        flex-grow: 1;
        padding: 20px;
        /* Уменьшаем отступы для параметров */
        width: 100%;
        /* Боксы займут всю доступную ширину */
        margin-left: 0;
        /* Убираем отступ слева */
        margin-right: 0;
        /* Убираем отступ справа */
    }

    .param-name::before {
        display: none;
        /* Скрываем черную полосу перед именем параметра */
    }

    .params-container {
        gap: 10px;
        grid-template-columns: 1fr;
        /* Одна колонка для мобильной версии */
        margin-left: 0;
        /* Убираем отступ слева */
        margin-right: 0;
        /* Убираем отступ справа */
    }

    .report-container {
        width: 100vw;
        /* Ширина контейнера равна 100% ширины экрана */
        max-width: 1000px;
        /* Максимальная ширина контейнера */
        margin: 0 auto;
        /* Центрируем контейнер на странице */
    }

    .params-row {
        gap: 10px;
        grid-template-columns: 1fr;
        /* Одна колонка для мобильной версии */

    }

    .competency-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        /* Поменять направление флекса */
    }

    .text {
        flex: 1;
        text-align: center;
        /* Выравниваем текст по центру */
    }

    .image {
        margin: 0 auto;
        /* Устанавливаем автоматичесные отступы слева и справа, чтобы изображение было по центру */
        text-align: center;
        /* Выравниваем текст по центру, если есть */
    }
    
    .image img {
        max-width: 70%;
        /* Устанавливаем максимальную ширину изображения на случай, если оно превысит ширину родительского контейнера */
    }

    .param-box,
    .param-box-extra-margin,
    .special-box {
        width: 100vw;
    }

    .param-name {
        margin-bottom: 10px;
    }
    .param-description {
        margin-bottom: 10px;
    }
    
}