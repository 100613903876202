.header-container {
    background-color: #f8f9fa;
    padding: 0px 10px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.header-offset {
    height: 60px; /* Высота должна соответствовать высоте header-container */
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;

}

.logo {
    margin-left: -50px;
    height: 70px;
}

.header-right {
    display: flex;
    align-items: center;
}

.logout-button {
    margin-left: 20px;
    padding: 5px 10px;
    background-color: #fa5400;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #db4900;
}

.settings-icon {
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    margin-right: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.settings-icon:hover {
    transform: rotate(20deg); /* Rotate the icon by 20 degrees on hover */
}

.chat-icon {
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    margin-right: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out; /* Smooth transition */
}
/* Optional: add a keyframes animation if you want a continuous rotation effect */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(20deg);
    }
}