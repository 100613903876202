/* Общие стили */
.div {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
}

.div2 {
  gap: 20px;
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  flex: 1 1 auto; /* Позволяет колонкам адаптироваться */
  margin-left: 0;
}

.div3 {
  display: flex;
  flex-direction: column;
  font: 400 14px Public Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.ReturnToPersonalAccount {
  color: #e85612;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
}

.ReturnToPersonalAccount:hover {
  text-decoration: underline;
  text-decoration-color: #fa5400;
}

.navigationSideBar {
  border-radius: 4px;
  margin-top: 20px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px; /* Максимальная ширина для боковой навигации */
  color: var(--Gray-600, #5f6c72);
  padding: 16px 0;
}

.dashboardNavItem,
.dashboardNavItem2 {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 24px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
}

.dashboard {
  flex-grow: 1;
  width: 100%;
  max-width: 300px; /* Максимальная ширина для дашборда */
}

.column2 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  flex: 1 1 auto; /* Позволяет колонкам адаптироваться */
  margin-left: 20px;
}

.setting {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  font: 14px Public Sans, sans-serif;
}

.accountSetting {
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  background: var(--Gray-00, #fff);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-width: 600px; /* Максимальная ширина для настроек аккаунта */
  padding: 76px 80px 270px;
}

.div4 {
  display: flex;
  margin-bottom: -54px;
  width: 100%;
  max-width: 600px; /* Максимальная ширина для дополнительного блока */
  flex-direction: column;
}

.inputField {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: 400;
  line-height: 1;
  justify-content: flex-start;
}

.location {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  justify-content: flex-start;
}

.country {
  display: flex;
  width: 100%;
  max-width: 300px; /* Максимальная ширина для выбора страны */
  flex-direction: column;
  justify-content: flex-start;
}

.selectLanguageForReport {
  color: var(--Gray-900, #191c1f);
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px; /* Максимальная ширина для выпадающего списка */
}

.select {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  color: #475156;
  margin-top: 10px;
  border: 1px solid var(--Gray-100, #e4e7e9);
  border-radius: 4px;
  background-color: var(--Gray-00, #fff);
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23475156" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 011 0L8 9.293l5.354-4.647a.5.5 0 11.707.707l-5.707 5.707a.5.5 0 01-.707 0L1.646 5.354a.5.5 0 010-.707z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.select:hover {
  border-color: #475156;
}

.dashboardNavItem.activeCategory,
.dashboardNavItem2.activeCategory {
  background-color: #fa5400;
  color: #fff;
}

.dashboardNavItem:hover,
.dashboardNavItem2:hover {
  background-color: #f7c6a3;
  cursor: pointer;
}

.dashboardNavItem2 {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.select option {
  background-color: #fff;
  color: #475156;
}

@supports (-webkit-appearance: none) {
  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.img2 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  margin: auto 0;
}

.button {
  align-self: start;
  border-radius: 2px;
  background-color: #fa5400;
  margin-top: 42px;
  gap: 8px;
  margin-left: 0;
  color: var(--Gray-00, #fff);
  letter-spacing: 0.17px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  border-radius: 8px;
  text-align: center;
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .div2 {
    flex-direction: column;
    gap: 0;
  }

  .column {
    flex: 1 1 auto;
  }

  .accountSetting {
    padding: 20px;
  }

  .navigationSideBar {
    width: 100%;
    max-width: none; /* Сбросить максимальную ширину */
  }

  .dashboardNavItem,
  .dashboardNavItem2 {
    padding: 10px 16px;
  }

  .dashboard {
    max-width: 100%; /* Позволяет дашборду занимать всю доступную ширину */
  }

  .column2 {
    flex: 1 1 auto;
    margin-left: 0;
  }

  .div4 {
    margin-bottom: 0;
  }

  .country {
    max-width: 100%; /* Позволяет выбору страны занимать всю доступную ширину */
  }
}