body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}


.content {
  margin-top: 80px; /* Выставляем отступ сверху, равный высоте хедера */
  margin-bottom: 20px;
  padding-top: 20px; /* Добавляем фоновый отступ сверху */
}



.container, .question-container, .Welcome-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px; /* фиксированная ширина */
  margin: auto;
  text-align: center; /* Выравниваем текст по центру */
}

.button-container {
  text-align: center; /* Выравниваем кнопки по центру */
  margin-top: 20px; /* Добавляем отступ сверху */
}

h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.login{
  text-align: center;
}
.registration{
  text-align: center;
}
.yourLink {
  text-align: center; /* Центрируем текст */
  max-width: 500px; /* Задаем максимальную ширину текста */
  margin: 0 auto; /* Центрируем элемент по горизонтали */
  margin-bottom: 20px;
}

.copyButton {
  border: 1px solid #fa5400;
  background-color: transparent; /* убираем фон */
  color: #232322; /* цвет текста кнопки */
  padding: 5px 10px; /* уменьшаем отступы внутри кнопки */
  font-size: 14px; /* размер шрифта */
  width: auto; /* кастомная ширина кнопки */
  height: auto; /* кастомная высота кнопки */
  margin-left: auto; /* установка отступа слева на автоматический */
  margin-right: auto; /* установка отступа справа на автоматический */
  display: block; /* чтобы кнопка была в одной строке с ссылкой */
  margin-bottom: 10px;
}

.copyButton:hover {
  background-color: transparent;
  cursor: pointer; /* меняем курсор на указатель */
}

p {
  color: #555;
  text-align: center;
  margin-bottom: 10px;
}

form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Выравниваем элементы формы по центру */
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"],
button {
  width: calc(100% - 22px); /* фиксированная ширина с учетом padding и border */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

input[type="radio"] {
  display: none;
}


label {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: calc(100%);
}

label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fa5400;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #fff;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
}

input[type="radio"]:checked + label::before {
  background-color: #fa5400;
}

label:hover {
  background-color: #e0e0e0;
}

input:focus {
  border-color: #fa5400;
}

button {
  width: calc(100% - 40px); /* фиксированная ширина с учетом padding и border */
  padding: 10px;
  background-color: #fa5400;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  display: block; /* Размещаем кнопки как блочные элементы */
  margin: 0 auto; /* Центрируем кнопки */
  height: 50px; /* Задаем высоту кнопок */
  font-size: 16px; /* Увеличиваем размер текста в кнопках */
  line-height: 1; /* Нормализуем интерлиньяж */
}

button:hover {
  background-color: #db4900;
}

.error {
  color: #ff0000;
  text-align: center;
  margin-top: 20px;
}

.link {
  display: block;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;
  color: #3498db;
}

.registration-form label::before {
  display: none;
}

.Welcome-form label::before {
  display: none;
}



.languageChoise::before {
  display: none;
  width: 100%;
}

.SaveButton {
  margin-top: 20px;
}

.input-container {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 600px;
}

.input-container input[type="text"],
.input-container .copyButton {
  height: 40px; /* Set a fixed height */
  width: 100px;
  padding: 0 10px; /* Adjust padding to maintain consistent size */
}

.input-container input[type="text"] {
  flex: 1;
  padding-right: 10px; /* Добавляем отступ справа */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  align-self: center;
}

.input-container .copyButton {
  align-self: center;
  margin-left: auto;
  background-color: #fa5400;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copyButton {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container .copyButton:hover {
  background-color: #db4900;
}

.container {
  margin-bottom: 20px;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.content {
  flex: 1; /* Занимаем всё доступное пространство */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  height: 80px; /* Высота вашего футера */
  padding: 40px;
  box-sizing: border-box;
}


@media only screen and (max-width: 600px) {


  .question-container {
    margin-top: 52.42px; /* Отступ сверху для контейнера question-container */
  }
  
  .container, .question-container, .Welcome-form {
    width: 90%; /* Ширина элементов на мобильных устройствах */
    padding: 10px; /* Уменьшаем внутренние отступы */
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="file"],
  button {
    width: 100%; /* Ширина инпутов и кнопок на мобильных устройствах */
    margin-bottom: 8px; /* Уменьшаем отступ между элементами формы */
  }

  label {
    padding: 8px; /* Уменьшаем отступы меток на мобильных устройствах */
    margin-bottom: -4px; /* Уменьшаем расстояние между метками */
  }

  label::before {
    width: 18px; /* Уменьшаем размер маркера на мобильных устройствах */
    height: 18px;
    margin-right: 5px; /* Уменьшаем отступ между маркером и текстом */
  }

  button {
    height: 40px; /* Уменьшаем высоту кнопок на мобильных устройствах */
    font-size: 14px; /* Уменьшаем размер шрифта в кнопках на мобильных устройствах */
  }
}

input[type="checkbox"] {
  appearance: none; /* Отключаем стандартный внешний вид */
  -webkit-appearance: none; /* Для поддержки вебкит-браузеров */
  -moz-appearance: none; /* Для поддержки Firefox */
  width: 20px; /* Задаем ширину галочки */
  height: 20px; /* Задаем высоту галочки */
  border: 2px solid #fa5400; /* Устанавливаем цвет границы галочки */
  border-radius: 4px; /* Закругляем углы галочки */
  outline: none; /* Убираем контур фокуса */
  cursor: pointer; /* Устанавливаем курсор при наведении на галочку */
}

/* Добавляем стили для изменения цвета галочки при выборе */
input[type="checkbox"]:checked {
  background-color: #fa5400; /* Устанавливаем цвет фона галочки при выборе */
}

/* Добавляем пользовательский стиль для маркера */
input[type="checkbox"]::before {
  content: ""; /* Добавляем пустой контент */
  display: inline-block; /* Делаем элемент блочным */
  width: 12px; /* Задаем ширину маркера */
  height: 12px; /* Задаем высоту маркера */
  background-color: transparent; /* Устанавливаем прозрачный фон маркера */
  border-radius: 2px; /* Закругляем углы маркера */
  border: 2px solid white; /* Устанавливаем белую границу маркера */
}

/* Добавляем стили для маркера при выборе */
input[type="checkbox"]:checked::before {
  background-color: #fa5400; /* Устанавливаем цвет маркера при выборе */
}