.generate-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reports-section {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.reports-header {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: bold;
}

.no-reports {
  font-weight: bold;
  color: #888;
}

.card {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 5px 0;
  padding: 12px 16px;
  min-height: 100px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-header {
  font-size: 14px;
}

.card-body {
  font-size: 14px;
  margin-top: 4px;
}